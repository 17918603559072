import { navigate, type PageProps } from 'gatsby'
import React from 'react'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Home from 'components/landing/Home'
import LandingScience from 'components/landing/LandingScience'
import paths from 'constants/paths'
import storage from 'constants/storage'
import * as cookie from 'libs/cookie'

type Props = PageProps

export default function HomeArthritis({ location }: Props) {
  React.useEffect(() => {
    cookie.createCookie(storage.HOME_KEY, storage.HOME_ARTHRITIS)
  }, [])

  React.useEffect(() => {
    // globalContext.isAuthed isn't ready on mount
    // and adding to dep causes redirect away soon as they auth
    if (cookie.getCookie(storage.REFRESH_TOKEN_KEY)) {
      navigate(paths.HOME_AUTHED, { replace: true })
    } else if (paths.getHomePath() !== location.pathname) {
      navigate(paths.getHomePath(), { replace: true })
    }
  }, [location.pathname])

  return (
    <Layout className="HomeArthritis" data-test-id="arthritis">
      <Head title="Age Bold – Exercise for healthy joints" titleOnly />
      <Home
        science={
          <LandingScience
            small="Exercise for healthy joints"
            title="1 in 2 older adults have arthritis and millions of people deal with joint pain on a daily basis.">
            Bold is here to help you reduce your pain and move with ease. Get science-backed workout
            programs without driving to a gym or paying for a training session. Try Bold strength
            and resistance training, tai chi, yoga, and more today!
          </LandingScience>
        }
      />
    </Layout>
  )
}
